$color-status-1: #333;
$color-status: #333;
$size-icon: 100px;
$size-circle: 150px;
$border-width: 5px;

.wrapper-wrongpath {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  img {
    width: 30%;
    height: auto;
  }

  .text {
    font-size: 30px;
    text-align: center;
  }
}

.success-dialog {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: #ffffff;
  border: 2px solid #28a745;
  border-radius: 8px;
  padding: 20px;
  z-index: 1000;
}

.wrapper-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .product {
    font-family: "Roboto Condensed", sans-serif;
    width: 100%;

    .content-body_product {
      text-transform: uppercase;
      text-align: center;

      h3 {
        font-size: 25px;
        font-weight: 400;
      }
    }

    .check-failed {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;

      i {
        font-size: $size-icon;
        color: $color-status;
      }
    }

    i {
      font-size: $size-icon;
      color: white;
      padding: 50px;
      background-color: orange;
      border-radius: 50%;
    }
  }
}

.container-social {
  display: flex;
  justify-items: center;
  flex-direction: row;
  gap: 20px; // Add spacing between cards

  .card-social {
    flex: 1 1 calc(50% - 20px); // Two cards per row on desktop
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;

    @media screen and (max-width: 768px) {
      flex: 1 1 100%; // Stack vertically on mobile
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper-wrongpath img {
    width: 100%;
  }

  .wrapper-content .product {
    width: 100%;
    padding: 20px;
  }

  .container-social {
    width: 100%;
    flex-direction: column;
  }
}


@media screen and (max-width: 545px) {
  .wrapper-content .product {
    padding: 20px;
  }
}

.btn-back {
  text-align: center;
  display: block;
  color: #fff;
  background-color: #333;
  padding: 10px 10px;
  margin-top: 20px;

  &:hover {
    color: #fff;
  }
}