.home-page {
    .wrapperBlogImg {
        display: grid;
        grid-template-columns: 51.6% 24.2% 24.2%;
        margin-top: 30px;
        grid-gap: 20px;

        .wrapperTitle {
            position: absolute;
            bottom: 0px;
            left: 15px;
            padding: 15px;
            margin-right: 15px;
            margin-bottom: 15px;
            background: linear-gradient(to right, #0011FC, #4ECCE5);
            border-radius: 20px;
            font-weight: bold;
            text-transform: uppercase;
            color: white;

            &:hover {
                background-color: rgba(255, 255, 255, 0.8);
                color: black;
                transition: background-color 500ms linear;
            }
        }

        img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 20px;
        }

        .gridItem1,
        .gridItem3,
        .gridItem4 {
            aspect-ratio: 1 / 1;
            /* Square shape */
        }

        .gridItem2 {
            grid-column: span 2;
            /* Spans 2 columns */
            aspect-ratio: 2 / 1;
            /* Width = 2x Height */
        }

        .gridItem1 {
            grid-column: 1;
            grid-row: 1 / span 2;
            cursor: pointer;
            position: relative;
        }

        .gridItem2 {
            grid-column: 2 / span 2;
            grid-row: 1;
            cursor: pointer;
            position: relative;
        }

        .gridItem3 {
            grid-column: 2;
            grid-row: 2;
            cursor: pointer;
            position: relative;
        }

        .gridItem4 {
            grid-column: 3;
            grid-row: 2;
            cursor: pointer;
            position: relative;
        }
    }

    @media screen and (max-width: 768px) {
        .wrapperBlogImg {
            grid-template-columns: repeat(1, 1fr) !important;

            .gridItem1 {
                grid-column: 1;
                grid-row: 1;
            }

            .gridItem2 {
                grid-column: 1;
                grid-row: 2;
            }

            .gridItem3 {
                grid-column: 1;
                grid-row: 3;
            }

            .gridItem4 {
                grid-column: 1;
                grid-row: 4;
            }
        }
    }
}