.see-more-btn {
    /* Basic look */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;          /* space between text and arrow */
    padding: 0.6rem 1.2rem;
    font-family: inherit; /* optional: use your global font */
    font-size: 1rem;      /* adjust size as needed */
    font-weight: 600;
    text-transform: uppercase;
    color: #ff0066;       /* bright pink/red text */
    background-color: transparent;
    border: 2px solid #ff0066;
    border-radius: 999px; /* fully rounded corners */
    cursor: pointer;
    transition: all 0.2s ease;
  
    /* Optional: subtle letter spacing */
    letter-spacing: 0.08em;
  
    /* Hover / active states */
    &:hover {
      background-color: #ff0066;
      color: #fff;
    }
    &:active {
      transform: scale(0.98);
    }
  
    /* Optional: remove outline/focus ring for cleaner look */
    &:focus {
      outline: none;
    }
  
    /* If you want the arrow to have any special styling */
    .arrow {
      display: inline-block;
      transition: transform 0.2s ease;
      /* e.g. slide arrow a bit on hover */
    }
  
    &:hover .arrow {
      transform: translateX(3px);
    }
  }
  