// Main form container
.blog-form-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;

  h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

// Form fields
.form-group {
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
    margin-bottom: 8px;
    color: #555;
  }

  input,
  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }
}

// Section list styling
.section-list {
  .section-item {
    display: flex;
    align-items: center;
    gap: 10px;

    select {
      width: 150px;
    }

    input {
      flex: 1;
    }

    button {
      background-color: #e74c3c;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 8px 12px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #c0392b;
      }
    }
  }
}

// Buttons
.add-section-button,
.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.submit-button {
  align-self: center;
  width: 150px;
}

// Responsive design
@media (max-width: 600px) {
  .blog-form-container {
    padding: 15px;
  }

  .section-list {
    flex-direction: column;

    .section-item {
      flex-direction: column;
      align-items: flex-start;

      select,
      input {
        width: 100%;
      }

      button {
        align-self: flex-end;
        margin-top: 10px;
      }
    }
  }

  .submit-button {
    width: 100%;
  }
}