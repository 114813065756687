$color-icon: #0270ad;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.services {
    padding-right: 12%;
    padding-left: 12%;

    .service-caption {
        h2 {
            font-size: 43px;
            display: block;
            font-weight: 700;
            line-height: 1.4;
            margin-bottom: 22px;
        }

        p {
            color: #5e5e5e;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.5;
            padding: 0 15px;
        }
    }
}

.separator-line {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #ddd;
    margin: 0 auto 20px;
    overflow: hidden;

    .separator-animated {
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, #009A26, #fff);
        animation: slide 3s linear infinite;
    }
}

.blog-content-section {
    display: grid;
    grid-template-columns: 33% 1fr;
    grid-template-rows: repeat(3, auto);
    gap: 20px;

    .blog-content-item {
        border: 1px solid #ddd;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

        &.blog-first-item {
            grid-column: 1;
            grid-row: 1 / span 3; // Spans all three rows
            position: relative;

            .blog-item-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }

            .blog-content-info {
                position: absolute;
                bottom: 10px;
                left: 10px;
                background: linear-gradient(to right, rgba(0, 17, 252, 0.5), rgba(78, 204, 229, 0.5));
                color: white;
                padding: 10px;
                border-radius: 15px;
                font-size: 1.2em;
            }

            .blog-see-more-button {
                display: none;
            }
        }

        &.blog-other-items {
            display: flex;
            align-items: center;
            gap: 10px;
            height: 100%;

            .blog-item-image {
                width: 350px;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }

            .blog-content-info {
                align-self: start;
                flex-grow: 1;
                padding: 20px;

                h2 {
                    text-align: start;
                    font-size: 1.5em;
                    margin-bottom: 10px;
                }

                .blog-see-more-button {
                    display: inline-block;
                    padding: 3px 30px;
                    background: linear-gradient(to right, #0011FC, #4ECCE5);
                    color: white;
                    text-decoration: none;
                    border-radius: 15px;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #025b88;
                    }
                }
            }
        }
    }
}

/* Responsive Design Adjustments */
@media (min-width: 768px) {
    .blog-content-item.blog-other-items {
        height: 20%; // Adjust percentage for medium screens
    }
}

@media (min-width: 1024px) {
    .blog-content-item.blog-other-items {
        height: 25%; // Further adjust for large screens
    }
}

.home-page {
    .services {
        i {
            color: $color-icon;
        }
    }

    .title {
        color: $color-icon;
    }

    .testimonials {

        .tes-rating,
        .tes-name {
            color: $color-icon;
        }
    }
}

@media (max-width: 768px) {
    .blog-content-section {
        grid-template-columns: 1fr; // Stack all items
        grid-template-rows: auto;

        .blog-content-item {
            width: 100%;
            flex-direction: column;

            .blog-item-image {
                width: 100%;
            }

            .blog-content-info {
                text-align: center;
            }
        }
    }
}