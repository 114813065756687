/* 🔥 Color Variables for Consistency */
$pink: #ff007f;
$purple: #5a20c6;
$orange: #ff9e00;
$bright-red: #e60000;
$gold: #ffd700;

/* Import Font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

/* 🔥 Căn Giữa Tiêu Đề của Box */
.price-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Giữ title nằm trên cùng */
  align-items: center; /* Căn giữa nội dung */
  text-align: center;
  padding: 20px;
}

/* 🔥 Đồng Bộ Tiêu Đề Giữa Các Box */
.headline-h2 {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px; /* Giữ khoảng cách đồng nhất */
  text-align: center;
}

/* 🔥 Đảm Bảo "GENERAL 1" & "PLATINUM" Không Lệch */
.tfs-general,
.tfs-platinum,
.tfs-vip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Đảm bảo không bị lệch */
}

/* 🔥 Fix Cho Mobile */
@media screen and (max-width: 767px) {
  .headline-h2 {
    font-size: 24px; /* Giảm kích thước trên mobile */
  }
}

/* 🔥 Đảm bảo Button Luôn Nằm Ở Dưới Box */
.price-box {
  display: flex;
  flex-direction: column; /* Giữ nội dung box theo chiều dọc */
  justify-content: space-between; /* Căn đều nội dung, đẩy button xuống dưới */
  height: 100%; /* Đảm bảo box có chiều cao đồng đều */
}

/* 🔥 Đảm bảo vị trí button luôn ở dưới */
.tfs-price-register {
  margin-top: auto; /* Đẩy phần đăng ký xuống dưới */
  width: 100%;
}

/* 🔥 Đảm bảo Chiều Cao Cố Định Cho Các Box */
@media screen and (min-width: 992px) {
  .price-box {
    min-height: 550px; /* Đảm bảo box có chiều cao đồng đều */
  }
}

/* 🔥 Fire Animation Container */
.fire-animation-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

/* 🔥 Fire Effect on Pricing Box */
.fire-box {
  position: relative;
  border-radius: 15px;
  padding: 25px;
  background: linear-gradient(45deg, #0f6faf, #ff2a00);
  color: rgb(223, 13, 153);
  text-align: center;
  box-shadow: 0 0 15px rgba(8, 0, 255, 0.8);
  animation: fireGlow 1.5s infinite alternate;
  transition: transform 0.3s ease-in-out;
}

.fire-box:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 69, 0, 1);
}

/* 🔥 Sửa Màu Giá Thành Màu Tím Đậm */
.tfs-promo-price {
  font-size: 36px;
  font-weight: bold;
  color: #5a20c6; /* 🟣 Tím Đậm */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); /* Bóng đổ giúp nổi bật hơn */
  white-space: nowrap; /* 🔥 Giữ trên cùng một hàng */
  text-align: center;
}

/* 🟢 Chỉnh Lại Màu "Giá ưu đãi" */
.tfs-price-register p {
  font-size: 22px;
  font-weight: bold;
  color: #008000; /* 🟢 Xanh Lá Đậm */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

/* 🔥 Hiển Thị Rõ Hơn Trên Nền Sáng */
.hot-price {
  font-size: 42px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(241, 6, 6, 0.5);
  white-space: nowrap; /* 🔥 Không cho xuống dòng */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 🔥 Container bao bọc các box */
.price-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Chia thành 2 cột */
  grid-template-rows: repeat(2, auto); /* 2 hàng */
  gap: 20px; /* Khoảng cách giữa các box */
  justify-content: center; /* Căn giữa */
  align-items: center; /* Căn giữa hàng */
  width: 100%;
}

/* 🔥 Fire-Themed Register Button */
.tfs-btn-register {
  background: linear-gradient(45deg, #ff0000, #ff7300);
  color: white;
  font-weight: bold;
  font-size: 22px;
  padding: 12px 25px;
  border-radius: 15px;
  text-transform: uppercase;
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(255, 69, 0, 0.8);
  animation: fireGlow 1.5s infinite alternate;
}

.tfs-btn-register:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 20px rgba(255, 69, 0, 1);
}


/* 🔥 Pricing Box Styling */
.price-box {
  position: relative;
  border-radius: 15px;
  padding: 25px;
  background: white;
  color: black;
  text-align: center;
  box-shadow: 0 6px 18px rgba(199, 4, 4, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
/* 🔥 Price Container: Aligns Boxes in Proper Order */
.price-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centers content */
  align-items: stretch;
  gap: 20px; /* Space between boxes */
  width: 100%;
}

/* 🔥 Price Box Adjustments */
.price-box {
  flex: 1 1 280px; /* Ensures equal width with a minimum of 280px */
  max-width: 320px; /* Keeps all boxes uniform */
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* 🔥 Tạo Khoảng Cách Giữa "HẾT VÉ" và Button */
.sold-out-notice {
  background: linear-gradient(45deg, #4e0998, #89114dcf);
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 350px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  height: 60px;
  animation: pulseEffect 1.5s infinite alternate;

  margin-bottom: 15px; /* 🔥 Tạo khoảng cách với button */
}

/* 🔥 Đảm bảo nút "REGISTER" không bị dính */
.tfs-btn-register {
  margin-top: 10px;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  background: linear-gradient(45deg, #ff4500, #ff9100);
  color: white;
  text-transform: uppercase;
  box-shadow: 0px 4px 10px rgba(255, 69, 0, 0.8);
}

.tfs-btn-register:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 15px rgba(255, 69, 0, 1);
}

/* 🔥 Promo Banner */
.price-box-promo-banner {
  background: linear-gradient(45deg, #ff4e50, #fc913a);
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  min-width: 250px;
}

/* 🔥 Pulse Animation */
@keyframes pulseEffect {
  0% {
    transform: scale(1);
    box-shadow: 0 6px 15px rgba(214, 4, 4, 0.3);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 10px 25px rgba(175, 5, 181, 0.5);
  }
}

/* 🔥 Responsive Design */
@media screen and (max-width: 767px) {
  .price-box {
    width: 90%;
    margin: 15px auto;
  }

  .tfs-btn-register {
    font-size: 20px !important;
    padding: 10px 20px;
  }

  .fire-animation-container {
    height: 80px;
  }
}

 /* Parent container that holds both SOLD OUT and REGISTER banners */
 .price-container {
  display: flex;
  justify-content: space-between; // Distributes boxes evenly
  align-items: stretch; // Ensures equal height
  flex-wrap: nowrap; // Prevents breaking to a new row
  gap: 20px; // Adds spacing between boxes
  width: 100%;
}

/* Price Row Styling */
.price-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px; /* Creates space between price row and "SOLD OUT" */
}

/* SOLD OUT Styling */
.sold-out-notice {
  background: linear-gradient(45deg, #4e0998, #89114dcf);
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 350px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  height: 60px;
  animation: pulseEffect 1.5s infinite alternate; // Animation effect
}





/* Register & Payment banner */
.price-box-promo-banner {
background: linear-gradient(45deg, #ff4e50, #fc913a);
color: white;
font-size: 18px;
font-weight: bold;
padding: 10px 20px;
border-radius: 10px;
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
display: flex;
text-transform: uppercase;
justify-content: center;
min-width: 250px;
}


.tfs-close-modal {
  position: absolute;

  left: -13px;
  top: 0;
  color: white;
  font-size: 22px;
}


.the-first-step {
  position: relative;

  div,
  li {
    font-size: 18px;
    font-family: "Gilroy";
    font-weight: 500;
  }

  .tfs-btn-register {
    color: black;
    font-size: 24px;
    font-weight: 600px;
    padding: 10px;
    background: linear-gradient(to right, #cfaf75, #fed993b1, #cfaf75);
  }

  .animate-ring {
    -webkit-animation: phonering-alo-circle-img-anim 2s infinite ease-in-out;
    animation: phonering-alo-circle-img-anim 2s infinite ease-in-out;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  }

  .overlay {
    bottom: 0;
    top: unset;
  }

  .tfs-banner {
    position: relative;
    background-size: contain;

    .tfs-banner-content {
      bottom: 0;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .headline-h1 {
      background: -webkit-linear-gradient(#ff00b3, #d0b1e2);
      -webkit-background-clip: text;
      background-clip: text; // Standard property
      -webkit-text-fill-color: transparent;
    }
  }

  .background-behind {
    position: absolute;
    background: black;
    height: 100%;
    width: 100%;
    z-index: -2;
  }

  .box {
    position: relative;
    background: black;
    border-radius: 20px;

    .img-coachvas-02 {
      width: 450px;
    }
  }

  .box:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-21deg, #f8acff 0%, #696eff 100%);
    transform: translate3d(0px, 0px, 0) scale(1.005);
    filter: blur(2px);
    opacity: var(0.65);
    transition: opacity 0.3s;
    border-radius: inherit;
  }

  /* 
  * Prevents issues when the parent creates a 
  * stacking context. (For example, using the transform
  * property )
  */
  .box::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
  }

  .for-who {
    .title-h2 {
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
    }

    .for-who-star {
      font-size: 28px;
      margin-right: 15px;
      color: #ff00b3;
      // background-image: linear-gradient(white, red);
    }

    .box {
      background-color: white;
    }

    .for-who-content {
      background-color: white;
    }
  }

  .tfs-coachvas {
    position: relative;

    .tfs-coachvas-content {
      position: absolute;
      bottom: 0;
      left: 20px;

      .img-coachvas-03 {
        position: absolute;
        width: 80%;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -10%);
      }
    }
  }

  .course-offline-testimonial {
    margin-top: 120px;

    .headline-h1 {
      font-family: "Roboto-Regular";
      background: -webkit-linear-gradient(white, white);
      -webkit-background-clip: text;
      background-clip: text; // Standard property
      -webkit-text-fill-color: transparent;
    }
  }

  .tfs-benefit {
    .archiment {
      .headline-h1 {
        background: -webkit-linear-gradient(rgb(255, 0, 234), #8420be);
        -webkit-background-clip: text;
        background-clip: text; // Standard property
        -webkit-text-fill-color: transparent;
        font-size: 64px;
        line-height: 64px;
      }

      .archiment-title {
        text-transform: uppercase;
        color: rgb(249, 249, 249);
        font-size: 28px;
      }

      .tfs-archiment-box {
        align-self: stretch;
        border: 1px solid black;
        padding: 15px;
        font-size: 18px;
        font-weight: 600;
        color: white !important;
        background-image: -webkit-linear-gradient(rgb(255, 0, 234), #8420be);
        display: grid;
        align-items: center;
      }

      .animate-bounce {
        animation: bounce 2s infinite;
      }

      .archiment-desc {
        color: rgb(185, 185, 185);
      }
    }
  }

  .tfs-video {
    .headline-h1 {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
    }

    .buttonPlay {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      background-color: #fff;
      font-size: 26px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      left: calc(50% - 40px);
      top: calc(50% - 20px);
    }
  }

  .tfs-price {
    .tfs-price-headline {
      font-family: Montserrat, sans-serif;
      font-weight: bold;
      color: rgb(188, 12, 12);
      text-align: center;
      font-size: 42px;
      line-height: 42px;
      padding-top: 32px;
    }

    ul {
      margin-bottom: 0;
    }

    .headline-h2 {
      font-size: 32px;
      line-height: 32px;
      font-weight: bolder;
    }

    .price-box {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      background-color: white;
      width: 100%;

      .tfs-general {
        background: -webkit-linear-gradient(#000baa, #0097fc);
        -webkit-background-clip: text;
        background-clip: text; // Standard property
        -webkit-text-fill-color: transparent;
      }

      .tfs-platinum {
        background: -webkit-linear-gradient(#0400ff, #ff00c8);
        -webkit-background-clip: text;
        background-clip: text; // Standard property
        -webkit-text-fill-color: transparent;
      }

      .tfs-vip {
        background: -webkit-linear-gradient(#bd0331, #ff00c8);
        -webkit-background-clip: text;
        background-clip: text; // Standard property
        -webkit-text-fill-color: transparent;
      }
      


      .position-bottom {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 16px;
      }

      .position-vip {
        margin-left: -15px;
        margin-right: -15px;
      }

      .default-price {
        font-size: 24px;
      }
      ul li {
        font-size: 18px;
      }

      .tfs-promo-text {
        font-weight: bolder;
        color: red;
        color: red;
        font-size: 36px;
        font-weight: bolder;

        &-general {
          color: red;
          font-size: 36px;
          font-weight: bolder;
        }
      }
    }

    .tfs-price-img {
      align-self: flex-end;
      margin: 30px 0;
      right: 0;
      width: 150px;
      -webkit-animation: phonering-alo-circle-img-anim 2s infinite ease-in-out;
      animation: phonering-alo-circle-img-anim 2s infinite ease-in-out;
      -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
    }
  }

  .tfs-trainer {
    .tfs-trainer-content {
      display: flex;
      align-items: start;
    }

    img {
      height: 350px;
      min-width: 250px;
    }

    .tfs-trainer-img {
      position: relative;
    }

    .img-coach-thanh {
      scale: 0.92;
    }

    .img-trainer-title {
      position: absolute;
      bottom: 20px;
      width: 180px;
      height: 70px;
    }
  }

  .tfs-fixed-bottom {
    z-index: 888;
    position: fixed;
    bottom: 0;
    height: 100px;
    background-image: linear-gradient(rgb(255, 0, 191), #8420be);
    width: 100%;

    .img-register {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100px;
        bottom: 0;
      }

      .tfs-btn-register {
        // position: absolute;
        font-size: 28px;
      }
    }

    .title-h2 {
      font-size: 28px !important;
    }

    .tfs-fixed-bottom-content {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .position-bottom {
    position: relative !important;
  }
}

@media screen and (max-width: 767px)  {

  div,
  li,
  p {
    font-size: 16px;
    line-height: 16px;
  }

  .headline-h1 {
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .headline-h2 {
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .headline-h3 {
    font-size: 16px !important;
    line-height: 16px !important;
  }

  h4 {
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .box {
    .img-coachvas-02 {
      width: 100% !important;
    }
  }

  .tfs-banner {
    .headline-h1 {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .tfs-banner-content {
      bottom: 30px !important;
    }

    span {
      display: block;
      margin-top: 0 !important;
    }
  }

  .tfs-coachvas {
    .tfs-coachvas-content {
      left: 10px !important;
      right: 10px;
    }
  }

  .tfs-trainer {
    .tfs-trainer-content {
      display: block !important;
    }
  }

  .tfs-fixed-bottom {
    height: 70px !important;
    text-align: center;

    .img-register {
      right: 20px;
      bottom: 0;

      .tfs-btn-register {
        font-size: 18px !important;
      }
    }

    h2 {
      font-size: 18px;
    }

    .title-h2 {
      font-size: 18px !important;
    }

    .tfs-fixed-bottom-content {
      display: block !important;

      .title-h2 {
        font-size: 18px !important;
      }

      p {
        margin-bottom: 15px !important;
      }
    }
  }
}