.section-list {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tags-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-check {
    display: flex;
    align-items: center;
    gap: 10px;
}

label {
    font-size: 14px;
    color: #333;
}

input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.section-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.section-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

input[type="text"],
select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
}

button.remove-section-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-size: 1rem;
}

button.remove-section-button:hover {
    background-color: #c82333;
}