/****************************************
 * membership-overview.scss
 ****************************************/

/* 
  Overall container for the membership overview. 
  Gives a purple background with padding.
*/
.see-more-button-container {
  justify-content: center;
  display: flex;
  margin-top: 16px;
}
.booking-button {
  width: fit-content;
  justify-self: center;
  
  justify-content: center;
  display: flex;
  margin-left: 1rem;        /* space between text and button */
  padding: 0.5rem 1rem;
  background-color: #f18afc; /* a pinkish background */
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f42cf7; /* slightly lighter/different pink on hover */
  }
}

.membership-overview {
    display: flex;
    flex-direction: column;
    gap: 24px;          /* Space between cards */
    padding: 16px;
    background-color: #2d0142; /* Deep purple background */
  }
  .membership-images {
    display: flex;
    flex-wrap: wrap;   /* or 'nowrap' if you want them strictly in one row */
    gap: 16px;         /* space between images */
    margin-top: 16px;
  
    img {
      display: block;
      width: auto;    /* let the image decide width or set a fixed width if you like */
      max-height: 200px; /* limit the height so they don’t get too tall */
      border-radius: 8px;
      object-fit: cover; /* if you want them cropped proportionally */
    }
  }
  
  
  /* 
    Individual card: 
    We keep a consistent dark purple background 
    and white text for readability. 
  */
  .membership-card {
    background-color: #2d0142; 
    color: #fff;
    border-radius: 8px;
    border: 1px solid #57276d; /* Slightly lighter purple for a subtle border */
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  /* =====================
     MAIN TITLE (e.g. NỘI DUNG SẢN PHẨM)
     We'll make it look like a pink gradient box 
     with white text, as in your flyer screenshot
  ===================== */
  .membership-card-title {
    /* Create a gradient background for the title block */
    display: inline-block;
    padding: 8px 16px;
    border-radius: 8px;
    background: linear-gradient(90deg, #8d1bda, #a800ff);
    margin-bottom: 16px;
  
    .membership-card-title-text {
      margin: 0;
      font-size: 1.8rem;
      font-weight: 700;
      color: #fff; /* White text inside the gradient box */
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  .ticket-section .ticket-section-title{
    color: #f47373;
  }
  
  /* =====================
     SMALL TITLE (e.g. KHÓA HỌC/COURSE, COACHING 1:1)
     We'll use a pinkish color to stand out on dark background
  ===================== */
  .membership-card-small-title {
    margin-bottom: 12px;
  
    h2 {
      margin: 0;
      font-size: 1.65rem;
      font-weight: 700;
      color: #f18afc;  /* Light pink text, similar to the flyer */
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }
  
  /* =====================
     REMINDER SECTION
     (the note box at the bottom of each card)
  ===================== */
  .membership-card-reminder {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #4b056b; /* Darker purple box for the note area */
    border-left: 4px solid #f18afc; /* Pink accent on left side */
  
    .reminder-text {
      margin: 1.5;
      color: #fff;
      font-size: 1.5rem;
      font-style: italic;
      line-height: 45px;
    }
  }
  
  /* =====================
   SPECIAL SECTION 
   (e.g. coach names/hours)
===================== */
.membership-card-special {
  margin-bottom: 16px;

  .content-text {
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px;
    color: #fff;
    font-size: 2.5rem; /* Increase beyond 4rem — e.g. 5rem, 6rem, etc. */
    font-weight: 500;
    line-height: 1.2; /* Adjust line-height if needed */
  }
}



  
  /* =====================
     MAIN CONTENT SECTION
     (Bullet points, main text)
  ===================== */
  .membership-card-content {
    p {
      display: flex;
      align-items: center;     /* Center items vertically */
      margin: 0 0 8px;
      color: #fff;
      font-size: 1.25rem;
      line-height: 1.6;       /* Adjust line-height as needed */
    }
    .content-text {
      margin: 0;
      font-style: normal;
      font-size: 1.85rem;
      line-height: 1.6;       /* Matches paragraph line-height if you want */
    }
  }
  
  
  
  
  /* =====================
     HIGHLIGHT SECTION
     (Other benefits, discount highlights, etc.)
  ===================== */
  .membership-card-highlight {
    margin-top: 22px;
    padding-top: 22px;
    border-top: 1px dashed #f18afc; 
  
    p {
      display: flex;
      align-items: flex-start;
      margin: 0 0 8px;
      color: #fff; /* White text in highlight section as well */
      font-size: 1rem;
      line-height: 1.6;
  
      .highlight-text {
        display: inline-block;
        padding: 2px 6px;
        margin-left: 4px;
        background-color: #fffcad; 
        border-radius: 4px;
        color: #333; /* Dark text on yellow background */
        /* Subtle pulsing animation if needed */
        animation: highlightPulse 1.5s ease-in-out infinite alternate;
        font-size: 1.65rem;
        line-height: 1.6;
      }
    }
  }
  
  /* =====================
     PULSING KEYFRAMES 
     (for highlight-text background)
  ===================== */
  @keyframes highlightPulse {
    0% {
      background-color: #ffd73a;
    }
    100% {
      background-color: #ffb7b7; 
    }
  }
  
  /* =====================
     BULLET CIRCLE 
     (gold-ish circle with black text)
  ===================== */
  .number-circle {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    background-color: #ffd233;
    color: #333;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 850;
    margin-right: 12px;     /* Space between circle & text */
    font-size: 1rem;
    line-height: 1;
  }

  
  /* =====================
     FANSIPAN / KINABALU TEXT
     Golden/orange 3D effect
  ===================== */
  
  /* CHALLENGE SUBSECTION */
.membership-card-challenge {
    margin: 16px 0;
  
    .challenge-line {
      /* 
        This can help center them or make them bigger.
        Adjust as you like.
      */
      text-align: center;
      margin: 8px 0;
    }
  }
  
  /* For "Chinh phục đỉnh Fansipan" */
  .fansipan-text {
    display: inline-block;
    font-size: 2.6rem; /* Larger text to match flyer */
    font-weight: 700;
    color: #ffc600;
    text-transform: uppercase; 
    letter-spacing: 2.5px;
    text-shadow:
      1px 1px 0 #802400,
      2px 2px 0 #802400,
      3px 3px 2px rgba(0, 0, 0, 0.3);
  }
  
  /* For "Chinh phục đỉnh Kinabalu" */
  .kinabalu-text {
    display: inline-block;
    font-size: 2.6rem; /* Larger text to match flyer */
    font-weight: 700;
    color: #ff9e00;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    text-shadow:
      1px 1px 0 #751b00,
      2px 2px 0 #751b00,
      3px 3px 2px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    .video-study-container iframe {
        width: 400px;
        height: 300px;
    }

    .mountain-title-text {
        font-size: 18px; // Smaller font size for mobile
    }

    .promo-title {
        font-size: 1.25rem; // Adjust for smaller screens
    }

    .best-seller-badge {
        bottom: -50px; // Adjust position for smaller screens
    }

    .card-detail {
        padding: 15px; // Adjust padding for mobile
    }

    .color-box-item {
        width: 80px; // Adjust item width for mobile
    }
    .fansipan-text {
      display: inline-block;
    font-size: clamp(1.5rem, 5vw, 2.5rem);
    font-weight: 850;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    }
    .kinabalu-text {
      display: inline-block;
    font-size: clamp(1.5rem, 5vw, 2.5rem);
    font-weight: 850;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    }
    
    
  }    