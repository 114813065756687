
.event-detail {
    background-color: #7c0101; /* Dark red background */
    color: #fff;
    padding: 40px 20px;
  
    .event-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
    }
  
    .left-column {
      flex: 1;
      text-align: center;
  
      .event-image {
        max-width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow for better visibility */
        margin-bottom: 20px;
      }
  
      .event-info {
        text-align: center;
    
        
        .message-box {
          background-color: #ffc107; /* Bright box for contrast */
          color: #7c0101; /* Text color to contrast with the box */
          padding: 20px;
          border-radius: 10px;
          margin-top: 30px;
  
          h5 {
            font-size: 1.6rem;
            font-weight: bold;
            margin-bottom: 10px;
          }
  
          p {
            font-size: 1.2rem;
            line-height: 1.6;
          }
        }
      }
    }
  
    .right-column {
      flex: 1;
      background-color: #530101; /* Dark red background for contrast */
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  
      .program-box {
        h3 {
          font-size: 2.2rem;
          font-weight: bold;
          color: #b0984d; /* Golden heading */
          margin-bottom: 20px;
          text-align: center;
        }
  
        .list-group {
          padding: 0;
  
          .list-group-item {
            display: flex;
            align-items: center;
            background-color: transparent;
            color: #fff;
            padding: 15px 10px;
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            font-size: 1.2rem;
  
            &:last-child {
              border-bottom: none;
            }
  
            .badge {
              background-color: #ffc107; /* Golden badge */
              color: #7c0101; /* Dark red text */
              font-size: 1.5rem;
              font-weight: bold;
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              margin-right: 15px;
            }
          }
        }
  
        .program-item {
          font-size: 1.1rem;
          margin-bottom: 15px;
        }
  
        .mt-4 {
          text-align: center;
  
          p {
            font-size: 1.2rem;
            margin-bottom: 5px;
          }
  
          .contact-info {
            font-size: 1.1rem;
  
            i {
              margin-right: 5px;
            }
          }
        }
  
        .register-btn {
          margin-top: 20px;
          display: block;
          width: 100%;
          background-color: #ffc107; /* Golden button */
          color: #7c0101; /* Dark red text */
          font-size: 1.4rem;
          font-weight: bold;
          padding: 10px 20px;
          border-radius: 5px;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }
  