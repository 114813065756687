.time-table {
    margin: 16px 0;
    padding: 12px;
    background-color: #4b056b; /* or any subtle background color */
    border-radius: 6px;
  
    h4 {
      color: #fff;
      margin-bottom: 8px;
    }
  
    .time-slot-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px; /* space between slot buttons */
    }
  
    .slot-button {
      padding: 0.5rem 1rem;
      border: 1px solid #fff;
      color: #fff;
      background: transparent;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s ease;
  
      &.selected {
        background: #f18afc; /* highlight color */
        border-color: #f18afc;
        color: #333;
      }
  
      &:hover {
        background-color: #f18afc88; /* partial highlight on hover */
        color: #333;
      }
    }
  
    .selected-slot {
      margin-top: 16px;
      p {
        color: #fff;
        margin-bottom: 8px;
      }
  
      .confirm-button {
        padding: 0.5rem 1rem;
        border: none;
        background: #ffd233; /* gold color */
        color: #333;
        border-radius: 4px;
        cursor: pointer;
  
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
  