.card-page {
    padding: 20px;
    font-family: Arial, sans-serif;

    .page-title {
        font-size: 2rem; // Slightly smaller title for better mobile fit
        text-align: center;
        margin-bottom: 20px;
    }

    .card-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%; // Ensure card fits container
        max-width: 600px; // Prevent cards from being too wide
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        overflow: hidden; // Prevent content overflow

        @media (max-width: 768px) {
            flex-direction: column; // Stack content vertically for smaller screens
            text-align: center; // Center-align text for better readability
        }
    }

    .card-image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
        margin-right: 15px;

        @media (max-width: 768px) {
            margin-right: 0; // Remove margin when stacked
            margin-bottom: 10px; // Add space between image and content
        }
    }

    .card-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .card-title {
            font-size: 1.25rem; // Reduce title size slightly
            margin: 0;
        }

        .card-date {
            font-size: 0.85rem; // Adjust date size for readability
            color: #666;
        }

        .card-tags {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .card-tag {
                background-color: #666;
                color: white;
                font-size: 0.75rem;
                padding: 3px 8px; // Slightly larger padding for better tap target
                border-radius: 5px;
            }
        }
    }

    .see-more-button {
        display: inline-block;
        padding: 8px 12px; // Adjust padding for better tap target
        background-color: #007bff;
        color: white;
        text-decoration: none;
        font-size: 0.85rem;
        border-radius: 5px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }

        @media (max-width: 768px) {
            margin-top: 10px; // Add space when stacked
        }
    }
}