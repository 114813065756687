.blog-background {
    position: relative;
    height: 500px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3782c4;
}

.title-box {
    width: 70%;
    position: absolute;
    top: 25%;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
    background-color: rgba(128, 128, 128, 0.5);
    z-index: 2;

    h1 {
        font-size: 1.6rem;
        margin: 0;
        text-align: center;
    }
}

.split-screen-container {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
}

.left-section {
    flex: 40;
    background-color: #f6f4f1;
    display: flex;
    justify-content: center;
    align-items: start;
}

.vertical-separator {
    width: 2px;
    background-color: black;
}

.right-section {
    flex: 60;
    background-color: #f6f4f1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.separator-line-detail {
    position: relative;
    width: 35%;
    max-width: 300px;
    height: 1px;
    background-color: #ddd;
    margin: 0 auto 20px;
    overflow: hidden;

    .separator-animated {
        position: absolute;
        height: 100%;
        width: 100%;
        background: #36D900;
        animation: slide 3s linear infinite;
    }
}


.description-section {
    padding: 10px;
    font-size: 1.2rem;
    line-height: 1.6;
}

.card-column {
    display: flex;
    flex-direction: column;
    justify-self: center;
    gap: 20px;
    width: 80%;
}

.custom-card {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.blog-detail-tag-container {
    display: inline-block;
    /* Ensure each link is inline */
    justify-self: center;
    text-align: center;
    margin-bottom: 15px;
    padding: 5px;
    width: 60%;
    background-color: lightgrey;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-decoration: none;
    /* Remove underline from the link */
    color: black;
    /* Set text color */
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
        color: white;
        /* Change text color on hover */
    }
}

.blog-detail-item-image {
    width: 40%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
}

.blog-detail-see-more-button {
    display: inline-block;
    padding: 3px 10px;
    background: linear-gradient(to right, #0011FC, #4ECCE5);
    color: white;
    text-decoration: none;
    font-size: medium;
    border-radius: 10px;
    transition: background-color 0.3s;
    align-self: flex-end;

    &:hover {
        background-color: #025b88;
    }
}

.blog-detail-left-custom-container {
    padding-top: 7px;
    padding-bottom: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blog-detail-right-custom-container {
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-self: start;
    justify-self: start;
    box-sizing: border-box;
    flex-wrap: wrap;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.blog-detail-image {
    width: 80%;
    height: auto;
    margin: 10px 0;
}

@media (max-width: 768px) {
    .custom-card {
        flex-direction: column;
        align-items: center;
    }

    .blog-detail-item-image {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .blog-detail-tag-container {
        width: 80%;
        /* Make the tag containers wider on smaller screens */
    }

    .split-screen-container {
        flex-direction: column;
        /* Stack the sections vertically on smaller screens */
    }

    .left-section,
    .right-section {
        flex: 100%;
        /* Make each section take full width */
    }

    .right-section {
        padding-left: 10px;
        padding-right: 10px;
    }
}