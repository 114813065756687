/* styles.scss */

// General styling for the membership benefits page
.membership-benefits {
  background: linear-gradient(to bottom, #8B0000, #4B0000);
  color: #fff;
  padding: 60px 20px;
  font-family: 'Arial', sans-serif;
  text-align: center;
  border-radius: 8px;
}

// Title styling
.title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #FFD700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

// Grid layout for benefits
.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 20px;
}

// Styling for each benefit item
.benefit-item {
  background-color: #4c1c1c;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  border: 2px solid #FFD700;
}

.benefit-item:hover {
  transform: translateY(-10px);
}

.benefit-item h2 {
  font-size: 28px;
  color: #FFD700;
  margin-bottom: 15px;
}

.benefit-item p {
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
  color: #fff;
  font-style: italic;
}

// Highlight important text
.benefit-item p span {
  font-weight: bold;
  color: #FFD700;
  background-color: #4c1c1c;
  padding: 3px 6px;
  border-radius: 4px;
}

// Pricing section layout
.prices-section {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

// Styling for each price item
.price-item {
  background-color: #4c1c1c;
  padding: 40px 20px;
  border-radius: 12px;
  width: 280px;
  text-align: center;
  color: #fff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
  border: 2px solid #FFD700;
  margin: 15px;
}

.price-item h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #FFD700;
  text-transform: uppercase;
}

.price-item p:first-of-type {
  font-size: 28px;
  font-weight: bold;
  color: #FFD700;
  margin-bottom: 5px;
}

.price-item p:nth-of-type(2) {
  font-size: 36px;
  font-weight: bold;
  color: #D3D3D3;
  margin: 10px 0;
}

.price-item p:last-of-type {
  font-size: 28px;
  color: #FFD700;
  font-weight: bold;
}

// Styling for a footer section to make the page more complete
.footer {
  margin-top: 50px;
  background-color: #2c0e0e;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
}

.footer p {
  font-size: 16px;
  color: #D3D3D3;
  margin: 0;
}
