.ticket-section {
    text-align: center;
    margin-top: 16px;
  
    .ticket-section-title {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  
    .flag-container {
      display: flex;
      justify-content: center;
      gap: 2rem; // space between the flags
  
      .flag {
        width: 80px;
        height: 120px;
        background: #000;
        /* This creates the downward “point” shape */
        clip-path: polygon(
          0 0,
          100% 0,
          100% 75%,
          50% 100%,
          0 75%
        );
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
  
        .flag-top {
          font-size: 12px;
          margin-bottom: 2px;
        }
        .flag-number {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .flag-bottom {
          font-size: 16px;
          font-weight: 600;
  
          &.vip {
            color: gold;
          }
          &.gen {
            color: silver;
          }
        }
      }
    }
  
    .ticket-note {
      margin-top: 8px;
      font-size: 0.875rem;
    }
  }
  